import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GiFactory, GiShakingHands } from 'react-icons/gi';
import { TbPackageImport } from 'react-icons/tb';
import { WaveLayout } from '../../WaveLayout';
import { MainCard } from './MainCard';
import { useTranslation } from 'next-i18next';

export const CardsSection = () => {
  const { t } = useTranslation(['index']);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <WaveLayout spacingTop={0}>
      <Box pt={15}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainCard />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  height: '100%',
                  padding: mdUp ? 4 : 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textAlign: 'center',
                }}
              >
                <Stack spacing={2} alignItems="center">
                  <GiFactory fontSize={80} />
                  <Typography component="h3" variant="h5" fontWeight="bold">
                    {t('index:cards:one:title')}
                  </Typography>
                  <Typography component="h4" variant="body1">
                    {t('index:cards:one:text')}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                sx={{
                  height: '100%',
                  padding: mdUp ? 4 : 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textAlign: 'center',
                }}
              >
                <Stack spacing={2} alignItems="center">
                  <TbPackageImport fontSize={80} />
                  <Typography component="h3" variant="h5" fontWeight="bold">
                    {t('index:cards:two:title')}
                  </Typography>
                  <Typography component="h4" variant="body1">
                    {t('index:cards:two:text')}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Paper
                sx={{
                  height: '100%',
                  padding: mdUp ? 4 : 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textAlign: 'center',
                }}
              >
                <Stack spacing={2} alignItems="center">
                  <GiShakingHands fontSize={80} />
                  <Typography component="h3" variant="h5" fontWeight="bold">
                    {t('index:cards:three:title')}
                  </Typography>
                  <Typography component="h4" variant="body1">
                    {t('index:cards:three:text')}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </WaveLayout>
  );
};

import {
  Paper,
  Stack,
  Typography,
  Chip,
  Container,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import Image from 'next/image';
import { WaveLayout } from '../../WaveLayout';
import { useTranslation } from 'next-i18next';
import { Pagination, Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SignUpInput from './SignUpInput';

import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css';

const Testimonial = ({ name, imageSrc, title, body }) => {
  return (
    <Paper sx={{ py: 4, px: 2, my: 2, textAlign: 'center' }}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5">{name}</Typography>
        <Image
          src={imageSrc}
          width={80}
          height={80}
          alt={name}
          style={{ borderRadius: '50%' }}
        />
        <Chip
          label={title}
          size="small"
          sx={{
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
        />
        <Typography variant="body2">“{body}”</Typography>
      </Stack>
    </Paper>
  );
};

export const Testimonials = () => {
  const { t } = useTranslation(['index']);
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <WaveLayout spacingTop={150}>
      <Container maxWidth="md" sx={{ my: 8, py: 2 }}>
        <Swiper
          style={{ height: '100%', paddingBottom: 50 }}
          autoplay={{
            delay: 2500,
          }}
          freeMode
          slidesPerView={isXl ? 3 : isLg ? 3 : isMd ? 3 : isSm ? 2 : 1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, FreeMode]}
        >
          <SwiperSlide>
            <Testimonial
              name={t('index:testimonials:one:name')}
              imageSrc="/testimonials/3.jpeg"
              title={t('index:testimonials:one:title')}
              body={t('index:testimonials:one:body')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial
              name={t('index:testimonials:two:name')}
              imageSrc="/testimonials/1.jpeg"
              title={t('index:testimonials:two:title')}
              body={t('index:testimonials:two:body')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <Testimonial
              name={t('index:testimonials:three:name')}
              imageSrc="/testimonials/2.jpeg"
              title={t('index:testimonials:three:title')}
              body={t('index:testimonials:three:body')}
            />
          </SwiperSlide>
        </Swiper>
        <Box display="flex" gap={2}>
          <Box>
            <Image
              src="/logo-mobile.png"
              width="200"
              height={200}
              alt="Directory logo"
            />
          </Box>
          <Stack spacing={2} alignItems="flex-start">
            <Typography component="h2" variant="h4">
              {t('index:signUpNow:title')}
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {t('index:signUpNow:subtitle')}
            </Typography>
            <SignUpInput />
          </Stack>
        </Box>
      </Container>
    </WaveLayout>
  );
};

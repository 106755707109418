import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface Props {
  pageName?: string;
  title?: string;
  description?: string;
  keywords?: string;
  author?: string;
}

export const NextHead: FC<Props> = ({
  title,
  pageName,
  description,
  keywords,
  author,
}) => {
  const APP_TITLE = 'CAFTA-DR Textiles Directory';
  const pageTitle = title || [pageName, APP_TITLE].join(' - ');

  return (
    <Head>
      <title>{pageTitle}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {author && <meta name="author" content={author} />}
    </Head>
  );
};

import { HeroSection } from '../components/pages/index/HeroSection';
import { CardsSection } from '../components/pages/index/CardsSection';
import { AboutCaSection } from '../components/pages/index/AboutCaSection';
import { Testimonials } from '../components/pages/index/Testimonials';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Features } from '../components/pages/index/Features';
import { getStaticPropsWithTranslations } from '../utils/translation';
import { NextHead } from '../components/NextHead';
import { useTranslation } from 'next-i18next';

const nameSpaces = ['purchase-announcements', 'directory', 'common', 'index'];

const Index = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  return (
    <Box textAlign={isMdUp ? 'left' : 'center'}>
      <NextHead
        title={t('index:seo:title')}
        description={t('index:seo:description')}
        keywords={t('index:seo:keywords')}
      />
      <HeroSection />
      <CardsSection />
      <Box pt={8}>
        <AboutCaSection />
      </Box>
      <Features />
      <Testimonials />
    </Box>
  );
};

export const getStaticProps = getStaticPropsWithTranslations(nameSpaces);

export default Index;
